<template>
  <CRow>
    <CCol sm="12">
      <CCard>
        <CCardHeader>
          <h5>Lista {{ titleList }}</h5>
        </CCardHeader>

        <CCardBody>
          <!-- Modal di conferma eliminazione -->
          <CModal
            :key="'modal-' + Date.now()"
            v-bind:title="titleModal"
            color="danger"
            centered
            fade
            :show.sync="warningModal"
          >
            <div>{{ bodyModal }}</div>
            <template #footer>
              <CButton color="secondary" @click="warningModal = false">Annulla</CButton>
              <CButton color="danger" @click="confirmDelete">Conferma</CButton>
            </template>
          </CModal>

          <CButton class="m-1 add-manifesto" size="lg" color="primary" @click="addManifesto()">
            Aggiungi Manifesto
          </CButton>
          <br />
          <br />

          <!-- Tabella dati -->
          <CDataTable
            :items="loadedItems"
            :fields="fields"
            :table-filter="{
              external: true,
              lazy: true,
              label: 'Cerca',
              placeholder: 'Inserisci nome',
            }"
            :table-filter-value.sync="tableFilterValue"
            :items-per-page="10"
            :active-page="1"
            outlined
            :noItemsView="{
              noResults: 'Nessun risultato da filtrare disponibile',
              noItems: 'Nessun dato disponibile',
            }"
            hover
            :loading="loading"
          >
            <template #fotoManifestoCustom="{ item }">
              <td>
                <CImg v-bind:src="resolvePhoto(item)" width="240" height="180" className="mb-6" />
              </td>
            </template>

            <template #info="{ item }">
              <td>
                <h6>
                  Nome:
                  <div class="bold-text">{{ item.nome_manifesto }}</div>
                </h6>
                <h6>
                  Tipologia:
                  <div class="bold-text">{{ item.tipologia }}</div>
                </h6>

                <h6 v-if="item.nome_chiesa !== null">
                  Luogo rito/ricorrenza:
                  <div class="bold-text">{{ item.nome_chiesa }}</div>
                </h6>
                <h6
                  v-if="
                    item.tipologia !== 'Ringraziamento' && item.scelta_rito_ricorrenza === 'acceso'
                  "
                >
                  Data rito/ricorrenza:
                  <div class="bold-text">{{ resolveDatetime(item.giorno_ora_messa) }}</div>
                </h6>
              </td>
            </template>

            <template #data_pubblicazione="{ item }">
              <td>
                <h6>
                  Inserito:
                  {{ resolveDatetime(item.created_at) }}
                </h6>
                <h6>
                  Pubblicato:
                  <div class="bold-text">{{ resolveDatetime(item.data_pubblicazione) }}</div>
                </h6>
                <h6>
                  Aggiornato:
                  {{ resolveDatetime(item.updated_at) }}
                </h6>
              </td>
            </template>

            <template #visualizzazioni="{ item }">
              <td>
                <h6>
                  Visualizzazioni totali:
                  <div class="bold-text">{{ item.visualizzazioni_totali }}</div>
                </h6>

                <h6 v-if="item.visualizzazioni > 0">
                  Visualizzazioni sito:
                  <div class="bold-text">{{ item.visualizzazioni }}</div>
                </h6>

                <h6 v-if="item.visualizzazioni_app > 0">
                  Visualizzazioni app:
                  <div class="bold-text">{{ item.visualizzazioni_app }}</div>
                </h6>

                <h6 v-if="item.conteggio_stati_app > 0">
                  Conteggio stati app:
                  <div class="bold-text">{{ item.conteggio_stati_app }}</div>
                </h6>

                <h6 v-if="item.visualizzazioni_facebook > 0">
                  Visualizzazioni facebook:
                  <div class="bold-text">{{ item.visualizzazioni_facebook }}</div>
                </h6>

                <h6 v-if="item.click_post_facebook > 0">
                  Click post facebook:
                  <div class="bold-text">{{ item.click_post_facebook }}</div>
                </h6>

                <h6 v-if="item.conteggio_stati_facebook > 0">
                  Conteggio stati facebook:
                  <div class="bold-text">{{ item.conteggio_stati_facebook }}</div>
                </h6>
              </td>
            </template>

            <template #comuni="{ item }">
              <td>
                <li v-for="value in item.comuni" :key="value.id">
                  {{ value.comune }}
                </li>
              </td>
            </template>

            <template #bottoneVisualizzazione="{ item }">
              <td>
                <CButton
                  class="m-1 button-manifesto-lista"
                  size="md"
                  color="primary"
                  @click="edit(item)"
                >
                  Modifica
                </CButton>
                <CButton
                  class="m-1 button-manifesto-lista"
                  size="md"
                  color="danger"
                  @click="remove(item)"
                  :disabled="loading"
                >
                  Cancella
                </CButton>
              </td>
            </template>
          </CDataTable>

          <CPagination v-show="pages > 1" :pages="pages" :active-page.sync="activePage" />
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
const titleList = 'Manifesti';
const bodyModal = 'Attenzione! Sei sicuro di voler cancellare permanentemente questo manifesto?';
const titleModal = 'Eliminazione Manifesto';
const apiList = '/dashboard/api/v0/manifesti/list';
const apiRemove = '/dashboard/api/v0/manifesti/delete/';

const fields = [
  {
    key: 'fotoManifestoCustom',
    label: 'Foto',
    sort: false,
    filter: false,
  },
  {
    key: 'info',
    label: 'Info',
    sort: false,
    filter: false,
  },
  {
    key: 'data_pubblicazione',
    label: 'Data',
    sort: false,
    filter: false,
  },
  {
    key: 'comuni',
    label: 'Comuni in cui pubblicare',
    sort: false,
    filter: false,
  },
  {
    key: 'visualizzazioni',
    label: 'Visualizzazioni',
    sort: false,
    filter: false,
  },
  {
    key: 'bottoneVisualizzazione',
    label: 'Operazioni',
    sort: false,
    filter: false,
  },
];

export default {
  name: 'ListaManifesti',
  data() {
    return {
      sorterValue: { column: null, asc: true },
      columnFilterValue: {},
      tableFilterValue: '',
      titleList: titleList,
      titleModal: titleModal,
      bodyModal: bodyModal,
      activePage: 1,
      loadedItems: [],
      itemsPerPage: 10,
      idDelete: null,
      itemToDelete: null,
      loading: false,
      warningModal: false,
      pages: 0,
      currentPages: 1,
      fields: fields,
    };
  },
  watch: {
    async reloadParams() {
      await this.onTableChange();
    },
  },
  computed: {
    reloadParams() {
      return [this.sorterValue, this.columnFilterValue, this.tableFilterValue, this.activePage];
    },
  },
  methods: {
    async onTableChange() {
      this.loading = true;
      try {
        await this.getData();
      } catch (error) {
        console.error('Errore nel caricamento dei dati:', error);
      } finally {
        this.loading = false;
      }
    },

    async getData() {
      try {
        const url =
          this.tableFilterValue.length > 0
            ? `${apiList}?search=${this.tableFilterValue}`
            : `${apiList}?page=${this.activePage}`;

        const response = await axios.get(url);

        if (response.status === 200) {
          this.loadedItems = response.data.results;

          if (response.data.results.length > 0) {
            const firstValue = response.data.results[0];
            this.pages = Math.ceil(firstValue.number / this.itemsPerPage);
          } else {
            this.pages = 0;
          }
        }
      } catch (error) {
        console.error('Errore nel recupero dei dati:', error);
        throw error; // Propagare l'errore per gestirlo altrove
      }
    },

    edit(item) {
      this.$router.push({
        name: 'manifesto',
        params: { idManifesto: item.id, tipologia: item.tipologia },
      });
    },

    remove(item) {
      this.idDelete = item.id;
      this.itemToDelete = item;
      this.warningModal = true;
    },

    async confirmDelete() {
      if (!this.idDelete) return;

      this.loading = true;
      try {
        const response = await axios.delete(apiRemove + this.idDelete);

        if (response.status === 200 || response.status === 204) {
          this.loadedItems = this.loadedItems.filter((item) => item.id !== this.idDelete);
          await this.getData();
        }
      } catch (error) {
        console.error("Errore durante l'eliminazione:", error);
        alert("Si è verificato un errore durante l'eliminazione del manifesto. Riprova più tardi.");
      } finally {
        this.warningModal = false;
        this.idDelete = null;
        this.itemToDelete = null;
        this.loading = false;
      }
    },

    resolvePhoto(obj) {
      if (obj !== null && obj.foto !== null) {
        return 'https://funer24-cdn.com/' + obj.foto.foto_pagina_manifesto_mobile;
      } else {
        return obj.foto_manifesto;
      }
    },

    resolveDatetime(datetimeObj) {
      if (!datetimeObj) return 'N/D';
      return new Date(datetimeObj).toLocaleString('it-IT');
    },

    addManifesto() {
      this.$router.push({
        name: 'manifesto',
      });
    },
  },

  async mounted() {
    try {
      this.loading = true;
      await this.getData();
    } catch (error) {
      console.error("Errore durante l'inizializzazione:", error);
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style>
div.bold-text {
  font-weight: 800;
}

button.add-manifesto.btn-primary {
  right: 10px;
  position: absolute;
}
</style>
